import { validatePostcode } from "@storefront/core/data-resolver/user";
import Vue from "vue";
export default Vue.extend({
    methods: {
      async validatePostcodeHouseNumber() {
        if (this.showFullAddress == false ) {
          const list = await validatePostcode(
            this.form.address.postcode,
            this.form.address.house_number
          );
          if (list.length == 1) {
            this.foundAddresses = [];
            this.form.address.house_numberError = '';
            let address = list[0].street + " " + list[0].number;
            this.form.address.streetDisplay = list[0].street;
            this.form.address.streetDisplayState = true;
            let singleAddition = "";
            if (list[0].letter != null) {
              singleAddition = list[0].letter
              address = address + list[0].letter;
            } 
            if (list[0].addition != null) {
              singleAddition += list[0].addition;
              address = address + list[0].addition;
            }
            if(singleAddition != ""){
              this.form.address.addition = singleAddition
              this.form.address.additionState = true
            } else {
              this.form.address.addition = ''
              this.form.address.additionState = null
            }
  
            address = address + ", " + list[0].postcode + " " + list[0].city;
  
            this.form.address.city = list[0].city;
            this.form.address.cityState = true;
            this.foundAddress = address;
            this.validatePostCode();
            this.form.address.house_numberState = true;
          } else if (list.length == 0) {
              this.foundAddresses = [];
              this.foundAddress = "";
              this.form.address.house_numberError = this.$t("postcode_not_found");
              this.form.address.house_numberState = false;
              this.form.address.addition = '';
              this.form.address.additionState = null;
          } else if (list.length > 1) {
            if(this.foundAddresses.length > 0){
              this.foundAddresses = [];
            }
            this.form.address.house_numberError = '';
            list.forEach(function (item) {
              let address = item.street + " " + item.number;
              if (item.letter != null) {
                address = address + " " + item.letter;
              }
              if (item.addition != null) {
                address =
                  address + (item.letter == null ? " " : "") + item.addition;
              }
  
              address = address + ", " + item.postcode + " " + item.city;
              item.text = address;
              this.foundAddresses.push(item);
            }, this);
            // Sort foundAddresses based on letter and addition
            this.foundAddresses.sort(function (a, b) {
              // Handle null values for letter (treat null as empty string)
              const aLetter = a.letter || '';
              const bLetter = b.letter || '';
              const letterCompare = aLetter.localeCompare(bLetter);
              if (letterCompare !== 0) {
                return letterCompare;
              }
              // Handle null values for addition (treat null as 0)
              const aAddition = parseInt(a.addition) || 0;
              const bAddition = parseInt(b.addition) || 0;
  
              // If letter is the same, sort by addition (numeric order)
              return aAddition - bAddition;
            });
            if(this.selectedAddress === null){
              this.form.address.house_numberState = null;
            } else {
              let addition = "";
              if(this.foundAddresses[this.selectedAddress] != undefined){
                if (this.foundAddresses[this.selectedAddress].letter != null) {
                  addition = this.foundAddresses[this.selectedAddress].letter;
                }
                if (this.foundAddresses[this.selectedAddress].addition != null) {
                  addition += this.foundAddresses[this.selectedAddress].letter !== null 
                  ? (!isNaN(this.foundAddresses[this.selectedAddress].letter) 
                  && !isNaN(this.foundAddresses[this.selectedAddress].addition) ? "-" : "") 
                  + this.foundAddresses[this.selectedAddress].addition 
                  : this.foundAddresses[this.selectedAddress].addition;
                }
                let houseNumber = `${this.foundAddresses[this.selectedAddress].number}`;
                if(this.form.address.house_number != houseNumber){
                  this.selectedAddress = null;
                  this.form.address.house_numberState = null;
                }
                if(this.form.address.addition == '' 
                  || this.form.address.addition == null 
                  || this.form.address.addition != addition){
                  this.form.address.additionState = null;
                }
              } else{
                this.selectedAddress = null;
                this.form.address.house_numberState = null;
              }
            }
          }
        } else {
          if (this.form.address.postcode == "") {
            this.form.address.postcodeState = false;
            this.form.address.postcodeError = this.$t("postcode_required");
          } else {
            this.form.address.postcodeState = true;
          }
        }
      },
    }
})